@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap');

@font-face {
  font-family: 'SamsungOne';
  src: local('SamsungOne'),
    url('https://sebn.ams3.cdn.digitaloceanspaces.com/ideasthatmove/assets/fonts/SamsungOne-400.ttf')
      format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'SamsungOne';
  src: local('SamsungOne'),
    url('https://sebn.ams3.cdn.digitaloceanspaces.com/ideasthatmove/assets/fonts/SamsungOne-700.ttf')
      format('truetype');
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: 'SamsungSharpSans';
  src: local('SamsungSharpSans'),
    url('https://sebn.ams3.cdn.digitaloceanspaces.com/ideasthatmove/assets/fonts/SamsungSharpSans-Bold.otf')
      format('opentype');
  font-style: bold;
  font-weight: 700;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SamsungOne';
  font-weight: 400;
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
